import { Text, Wrapper, FormCheckbox, FormInput, Label } from '@datacamp/cdn-ui';
import { useRouter } from 'next/router';
import React from 'react';
import { toast } from 'react-toastify';

import { FormAuthCode } from '../Settings/Security/TwoStepAuthentication/AuthCode/FormAuthCode';
import { Button } from '@components/Button/Button';
import { FormWrapper } from '@components/FormWrapper/FormWrapper';
import { Head } from '@components/Layout/Head/Head';
import { showLiveChat } from '@components/LiveChat/LiveChat';
import { endPoints, externalRoutes, publicRoutes } from '@hooks/Routes/routes';
import { isEmail, isPasswordMinLength, isRequired } from '@validator';

import styles from './Login.module.css';

const MISSING_OTP = 'missingOtp';

export const Login: React.FC = () => {
  const [isCra, setIsCra] = React.useState<boolean | undefined>(undefined);
  const router = useRouter();
  const [step, setStep] = React.useState<'login-form' | 'two-factor'>('login-form');

  const to = router.query?.to;

  React.useEffect(() => {
    setIsCra(document.location.host.includes('ssladmin'));
  }, []);

  React.useEffect(() => {
    if (router.query['email-verified'] !== undefined) {
      toast.success('Email verified successfully. Please log in.', { type: 'success' });
      void router.replace(publicRoutes.auth.login.pathname);
    }
  }, [router.query]);

  return (
    <>
      <Head title="Login" titlePostfix={isCra ? ' | ČRA CDN' : undefined} />
      <Text
        align="center"
        className={step === 'login-form' ? 'mb-40' : 'mb-20'}
        color="white"
        display="block"
        size={24}
        tag="h1"
        weight="semibold"
      >
        {step === 'login-form' ? 'Log into your account' : 'Two-factor authentication'}
      </Text>
      <div className="mb-48" data-recording-disable>
        <FormWrapper
          endpointUrl={endPoints.login}
          excludedErrorTypes={[MISSING_OTP]}
          getValues={values => ({
            email: values.email.trim(),
            password: values.password.trim(),
            otp: values.otp || undefined,
            remember: values.remember,
            to,
          })}
          initialValues={{ email: '', password: '', otp: '', remember: false }}
          showSuccessMessage={false}
          validateAfterValueChange={step}
          watchForChanges={false}
          onError={error => {
            if (error.type === MISSING_OTP) {
              setStep('two-factor');
            }
          }}
        >
          {({ submitButtonProps }) => (
            <>
              <div className={step === 'login-form' ? 'mb-32' : 'mb-48'}>
                {step === 'login-form' ? (
                  <>
                    <Label htmlFor="email">Email address</Label>
                    <FormInput
                      autocomplete="email"
                      className="mb-32"
                      name="email"
                      validate={value =>
                        isRequired(value, 'Enter your email address') ?? isEmail(value)
                      }
                      autofocus
                    />
                    <Label htmlFor="password">Password</Label>
                    <FormInput
                      autocomplete="current-password"
                      className="mb-28"
                      name="password"
                      type="password"
                      validate={value => isPasswordMinLength(value, 8, 'Enter your password')}
                    />
                    <FormCheckbox name="remember">Keep me logged in</FormCheckbox>
                  </>
                ) : (
                  <div>
                    <Text align="center" className="mb-40" color="grey-400" size={14} tag="p">
                      Two-factor authentication is enabled for your account. Please enter the
                      verification code to log in.
                    </Text>
                    <div className={styles.authCodeWrapper}>
                      <FormAuthCode name="otp" variant="secondary" submitOnFill />
                    </div>
                  </div>
                )}
              </div>
              <Button
                {...submitButtonProps}
                color="primary"
                disabled={false}
                theme="button"
                width={{ desktop: 'full' }}
              >
                {step === 'login-form' ? 'Log in' : 'Verify'}
              </Button>
            </>
          )}
        </FormWrapper>
      </div>
      <Wrapper align="center">
        {step === 'login-form' ? (
          <>
            <Button
              color="grey-400"
              href={publicRoutes.auth.resetPassword.pathname}
              size="sm"
              theme="link"
            >
              Forgot password?
            </Button>
            {!isCra && (
              <>
                <Text className={styles.separator} color="white" size={15} tag="span">
                  |
                </Text>
                <Button
                  color="grey-400"
                  href={externalRoutes.createAccount}
                  size="sm"
                  theme="link"
                  external
                >
                  Request a tailored offer
                </Button>
              </>
            )}
          </>
        ) : (
          <Text color="grey-400" size={15} tag="p">
            Need help?{' '}
            <Button
              color="inherit"
              theme="link"
              weight="semibold"
              onClick={() => {
                showLiveChat();
              }}
            >
              Contact our support.
            </Button>
          </Text>
        )}
      </Wrapper>
    </>
  );
};
